// Defining used elements
let currentTheme;
const header = document.querySelector('header');
const themeSwitchButton = document.querySelector('#themeSwitchButton');
const currentThemeSettings = document.querySelector('#currentThemeSettings');

// Check if there is a prefered theme in localStorage
checkPreferedTheme();

// Switch theme button functionality
themeSwitchButton.addEventListener('click', switchTheme);

// Switch theme function
function switchTheme(){
    const darkThemeSettings = `
    :root {
        --current-theme-background: var(--background-dark);
        --current-theme-text: var(--text-dark);
        --current-theme-divider: var(--divider-dark);
        --current-theme-logo: var(--text-dark);
        --current-theme-accent: var(--accent-dark);
        --current-default-shadow: var(--default-shadow-dark);
        --current-minimal-shadow: var(--minimal-shadow-dark);
        --current-accent-border: var(--accent-border-dark);
        --current-bright-glass-background: var(--bright-glass-background-dark);
      }
      `;
    const lightThemeSettings = `
    :root {
        --current-theme-background: var(--background-light);
        --current-theme-text: var(--text-light);
        --current-theme-divider: var(--divider-light);
        --current-theme-logo: var(--text-light);
        --current-theme-accent: var(--accent-light);
        --current-default-shadow: var(--default-shadow-light);
        --current-minimal-shadow: var(--minimal-shadow-light);
        --current-accent-border: var(--accent-border-light);
        --current-bright-glass-background: var(--bright-glass-background-light);
      }
      `;
    if (currentTheme == 'dark') {
        localStorage.preferedTheme = 'light';
        currentTheme = 'light';
        themeSwitchButton.innerHTML = '<span class="icon ion--moon-outline icon-l"></span>';
        currentThemeSettings.innerHTML = lightThemeSettings;
    } else {
        localStorage.preferedTheme = 'dark';
        currentTheme = 'dark';
        themeSwitchButton.innerHTML = '<span class="icon ion--sunny-outline icon-l"></span>';
        currentThemeSettings.innerHTML = darkThemeSettings;
    }
};

// Check prefered theme function
function checkPreferedTheme(){
    currentTheme = localStorage.preferedTheme;
    if (currentTheme === 'dark') {
        currentTheme = 'light';
        switchTheme();
        return;
    };
    if (currentTheme === 'light') {
        currentTheme = 'dark';
        switchTheme();
        return;
    };
    if (!currentTheme) {
        currentTheme = 'light';
        switchTheme();
        return;
    };
};

// Apply sticky header styles when page is scrolled more than 20px down
window.addEventListener('scroll', function() {
    if (window.scrollY > 5) {
        header.classList.add('sticky-header');
    } else {
        header.classList.remove('sticky-header');
    }
});